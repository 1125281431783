<template>
  <div id="nav">
    <img id="img-logo" src="~@/assets/ic_launcher.png" />
    <span id="text-logo">墨记</span>

    <div class="tool-right">
      <div class="div-product-menu" @click="goProduct()">其它产品</div>

      <div class="div-contact" @click="goContact()">联系我们</div>
    </div>
  </div>
  <div class="home">
    <img class="img-bg" src="~@/assets/bg.png" />

    <div class="div-download">
      <div class="div-logo-parent">
        <div class="div-demo">
          <img class="img-demo" src="~@/assets/img_computer.png" />
        </div>
      </div>

      <h1
        style="
          margin-top: 32px;
          margin-bottom: 32px;
          font-weight: 900;
          font-size: 28px;
        "
      >
        免费下载应用
      </h1>

      <div class="div-download-app">
        <div class="div-app ios-app">
          <div class="app-code">
            <div class="app-logo mobile-app">
              <img src="~@/assets/ic_iphone.svg" />
            </div>
            <img class="code-ios img-code" src="~@/assets/code.png" />
          </div>

          <p class="app-name">墨记iOS版</p>
          <a
            class="app-download-button"
            href="https://a.app.qq.com/o/simple.jsp?pkgname=com.denglin.moji"
            target="_blank"
            >下载</a
          >
        </div>
        <div class="div-app android-app">
          <div class="app-code">
            <div class="app-logo mobile-app">
              <img src="~@/assets/ic_android.svg" />
            </div>
            <img class="code-android img-code" src="~@/assets/code.png" />
          </div>

          <p class="app-name">墨记Android版</p>
          <a
            class="app-download-button"
            href="https://a.app.qq.com/o/simple.jsp?pkgname=com.denglin.moji"
            target="_blank"
            >下载</a
          >
        </div>
      </div>

      <div class="div-download-app download-pc">
        <div class="div-app mac-app">
          <div class="app-code">
            <div class="app-logo">
              <img src="~@/assets/ic_mac.svg" />
            </div>
          </div>

          <p class="app-name">墨记Mac版</p>
          <a
            class="app-download-button"
            href="/download"
            target="view_window"
            >下载</a
          >
        </div>
        <div class="div-app win-app">
          <div class="app-code">
            <div class="app-logo">
              <img src="~@/assets/ic_windows.svg" />
            </div>
          </div>

          <p class="app-name">墨记Windows版</p>
          <a
            class="app-download-button"
            href="/download"
            target="view_window"
            >下载</a
          >
        </div>
      </div>
    </div>

    <h1
      style="
        margin-top: 32px;
        margin-bottom: 32px;
        font-weight: 900;
        font-size: 28px;
      "
    >
      其它产品
    </h1>
    <div class="div-product">
      <div class="product">
        <img class="img-product-logo" src="~@/assets/ic_app_wuji.png" />
        <p class="app-product-name">吾记-精美日记</p>
        <p class="app-product-desc">
          吾记，一款简约精致的日记本，加密记事、小情绪、生活小记、工作/学习记录，统统满足你！
        </p>
        <a
          href="http://wujiapp.com/"
          class="product-link"
          target="_blank"
          >了解更多 →</a
        >
      </div>
      <div class="product">
        <img class="img-product-logo" src="~@/assets/ic_app_jizhangya.png" />
        <p class="app-product-name">记账鸭-纯净记账本</p>
        <p class="app-product-desc">
          嗨~我是记账鸭，记账我是专一的，对你我是真心的，全身心专注为你记账，一切理财广告、社交干扰都被我拒之门外啦！
        </p>
        <a
          href="http://www.jizhangya.cn/"
          class="product-link"
          target="_blank"
          >了解更多 →</a
        >
      </div>

      <div class="product">
        <img class="img-product-logo" src="~@/assets/ic_app_todo.png" />
        <p class="app-product-name">知了清单-日程计划</p>
        <p class="app-product-desc">
          清单、任务、时间管理、计划表、待办事项、备忘录、笔记、提醒事项……统统满足你！
        </p>
        <a
          href="http://zltodo.com/"
          class="product-link"
          target="_blank"
          >了解更多 →</a
        >
      </div>

       <div class="product">
        <img class="img-product-logo" src="~@/assets/ic_app_mdays.png" />
        <p class="app-product-name">mDays-纪念日/倒数日/倒计时</p>
        <p class="app-product-desc">
          记录你生活中重要的日子，可以是纪念日、生日、高考倒计时、重要日程...
        </p>
        <a
          href="http://mdaysapp.com/"
          class="product-link"
          target="_blank"
          >了解更多 →</a
        >
      </div>

      <div class="product">
        <img class="img-product-logo" src="~@/assets/ic_app_moice.png" />
        <p class="app-product-name">Moice-录音标记的语音记事本</p>
        <p class="app-product-desc">
          Moice是一款高效录音标记的语音记事本。会议记录、课堂录音、语音日记、生活备忘、灵感捕捉、文艺创作…统统满足你！
        </p>
        <a href="http://moiceapp.com/" class="product-link" target="_blank"
          >了解更多 →</a
        >
      </div>
    </div>

    <div class="div-about">
      <div class="about">
        <p class="about-moji">联系我们</p>
        <p class="about-moji">安卓用户QQ群：655158697</p>
        <p class="about-moji">苹果用户QQ群：597098166</p>
        <p class="about-moji">联系邮箱：mojiteam@163.com</p>
        <p class="about-moji">微信公众号：墨记APP</p>
      </div>
      
    </div>
    <div class="div-record">
      <a
        href="https://beian.miit.gov.cn/#/Integrated/index"
        class="moji-record"
        target="_blank"
        >粤ICP备19024063号</a
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "HomeMobile",
  components: {},
  methods: {
    goProduct() {
      let div = document.getElementsByClassName("div-product")[0];
      div.scrollIntoView();
    },

    goContact() {
      let div = document.getElementsByClassName("div-about")[0];
      div.scrollIntoView();
    },
  },
};
</script>

<style scoped>
#nav {
  padding: 0px 20px;
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  display: absolute;
  z-index: 200;
  border-bottom: 1px solid #f9f9f9;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

#img-logo {
  height: 50px;
  width: 50px;
}

#text-logo {
  font-weight: 900;
  font-size: 18px;
  color: #111111;
  margin-left: 6px;
  white-space: nowrap;
}

.tool-right {
  display: flex;
  height: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
}

.div-product-menu {
  padding-left: 12px;
  padding-right: 12px;
  height: 28px;
  width: fit-content;
  text-align: center;
  border-radius: 18px;
  line-height: 28px;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  white-space: nowrap;
  cursor: pointer;
}

.div-contact {
  padding-left: 12px;
  padding-right: 0px;
  height: 28px;
  width: fit-content;
  text-align: center;
  line-height: 28px;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  white-space: nowrap;
  cursor: pointer;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.home {
  position: relative;
  height: calc(100% - 100px);
  width: 100%;
  overflow-y: scroll;
  overflow-x: auto;
  background: #fafafa;
}

.border {
  height: 1px;
  width: 100%;
}

.div-download {
  border-bottom: 1px solid #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  background: #ffffff;
}

.div-logo-parent {
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.div-logo {
  height: 100%;
  flex-grow: 2;
  z-index: 100%;
}

.img-logo {
  vertical-align: middle;
  width: 50%;
}

.div-demo {
  z-index: 100;
  flex-grow: 8;
}

.img-demo {
  margin-top: 60px;
  margin-bottom: 60px;
  width: 80%;
}

.img-bg {
  position: absolute;
  right: 0;
  margin: 0 auto;
  top: 20px;
  width: 100%;
}

.div-download-app {
  width: calc(100% - 0px);
  height: auto;
  display: flex;
  flex-wrap: wrap;
  /* 自动换行 */
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}

.download-pc {
  margin-bottom: 40px;
}

.div-app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 140px;
  margin-bottom: 42px;
  cursor: pointer;
  margin-right: 12px;
  background: #ffffff;
}

.app-code {
  position: relative;
  height: 130px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-logo {
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 65px;
  background-color: #1b1c23;
}

.img-code {
  z-index: 100;
  position: absolute;
  left: 0;
  top: 0;
  height: 130px;
  width: 130px;
  opacity: 0;
  transition: 1s;
  overflow: hidden;
}

.app-logo img {
  height: 36px;
  width: 36px;
}

.app-code:hover .mobile-app {
  opacity: 0;
  transition: 1s;
  overflow: hidden;
}

.app-code:hover .img-code {
  opacity: 1;
  transition: 1s;
  overflow: visible;
}

.app-name {
  color: #333333;
  font-weight: 700;
  font-size: 16px;
  white-space:nowrap; 
}

.app-download-button {
  height: 36px;
  width: 126px;
  background-color: #1b1c23;
  color: white;
  border-radius: 6px;
  font-weight: 700;
  line-height: 36px;
  text-decoration: none;
}

.app-download-button:hover {
  background: #48494f;
}

.div-product {
  height: auto;
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 40px;

  display: flex;
  padding-bottom: 12px;
  flex-wrap: wrap;
  /* 自动换行 */
  justify-content: center;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #f9f9f9;
}

.product {
  display: flex;
  flex-direction: column;
  justify-content: left;
  justify-items: left;
  padding-bottom: 32px;
  width: 100%;
  margin-bottom: 22px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #f9f9f9;
  border-bottom: 1px solid #f9f9f9;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
}

.img-product-logo {
  height: 60px;
  width: 60px;
  margin-top: 32px;
  margin-left: 42px;
  border-radius: 50px;
}

.app-product-name {
  color: #333333;
  font-weight: 700;
  font-size: 22px;
  margin-top: 22px;
  margin-left: 42px;
  margin-right: 42px;
  margin-bottom: 0px;
  text-align: left;
}

.app-product-desc {
  color: #333333;
  font-size: 16px;
  margin-top: 22px;
  margin-left: 42px;
  margin-right: 42px;
  margin-bottom: 0px;
  line-height: 1.6;
  text-align: left;
}

.product-link {
  color: #00b548;
  font-size: 18px;
  font-weight: 500;
  margin-top: 22px;
  text-decoration: none;
  text-align: left;
  margin-left: 42px;
}

.div-about {
  height: auto;
  width: calc(100% - 0px);
  display: flex;
  padding-top: 32px;
  padding-bottom: 32px;
  flex-wrap: wrap;
  /* 自动换行 */
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  padding: 22px;
  margin-bottom: 22px;
}

.about-moji {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
  margin-top: 22px;
  text-align: left;
}

.div-record {
  background: #ffffff;
  padding-bottom: 22px;
}
.moji-record {
  color: #333333;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  text-align: left;
}
</style>
